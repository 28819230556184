// import axios from "axios";
// import env from "../config";
import axios from "../api/axios";
// const client = axios.create({
//   baseURL: env.COMPANY_ENDPOINT,
//   // baseURL: `http://35.236.93.48/api/Client`,
//   json: true
// });

export default {
  async execute(method, resource, data) {
    return axios.companyEndPoint({
      method,
      url: resource,
      data
    }).then(req => {
      return req.data;
    });
  },
  getAllCustomers() {
    // https://dev-trackunit-gw.incus5.com/companyms/v1.0/Company/basic/all

    return this.execute("get", `/Company/basic/all`);
  },
  createCustomerOnboarding(payload) {
    // https://dev-trackunit-gw.incus5.com/companyms/v1.0/Company
    return this.execute("post", `/Company`, payload);
  },
  getCustomerbyId(id){
    // https://dev-trackunit-gw.incus5.com/companyms/v1.0/Company/1ec32338-8e8b-4701-b555-74237f5409cf
    return this.execute("get", `/Company/${id}`);

  },
  historyUpdate(companyid ,duration){
    
  // https://dev-trackunit-gw.incus5.com/companyms/v1.0/Company/a/triggerhistorydata?duration=11
  return this.execute("put", `/Company/${companyid}/triggerhistorydata?duration=${duration}`);
  },
  updateReportsCustomer(companyid , payload){
    
    // https://dev-trackunit-gw.incus5.com/companyms/v1.0/Company/a
    return this.execute("put", `/Company/${companyid}` ,payload);


  },
  getCompanyById(id){
    return this.execute("get", `/Company/${id}`);
  }
}